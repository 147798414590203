<template>
  <div class="field is-grouped">
    <div class="chat__fixed-panel" ref="panel">
      <p class="control is-expanded">
        <textarea class="textarea chat__input-field"
              rows="1"
              v-model="message" ref="message"
              :class="{ 'is-danger': $v.message.$error && !saving }"
              @input="$v.message.$touch()"
        >
        </textarea>
      </p>
      <p class="control">
        <a class="button is-info" @click.prevent="send" :class="{ 'is-loading': saving, 'is-disabled': $v.$invalid }">
          Отправить
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import autosize from 'autosize'
import EventBus from './../../event-bus'
import { CHAT_INPUT_RESIZED } from './../../events'

export default {
  name: 'MessageInput',
  props: ['onSend', 'saving'],
  methods: {
    send () {
      if (this.message) {
        this.onSend(this.message)
        this.message = ''
      }
    },
    resizeHandler: function () {
      EventBus.$emit(CHAT_INPUT_RESIZED, { height: this.$refs.panel.offsetHeight })
    }
  },
  mounted () {
    this.$refs.message.addEventListener('autosize:resized', this.resizeHandler)
    autosize(this.$refs.message)
  },
  beforeDestroy () {
    this.$refs.message.removeEventListener('autosize:resized', this.resizeHandler)
    autosize.destroy(this.$refs.message)
  },
  data () {
    return {
      message: ''
    }
  },
  validations: {
    message: {
      required
    }
  }
}
</script>

<style>
  .app-main {
    transform: none !important;
  }
</style>

<style scoped>
  input {
    margin: 10px;
    padding: 10px;
    font-size: 18px;
  }

  .chat__fixed-panel {
    position: fixed;
    bottom: 0;
    left: 180px;
    right: 0;
    background: #fff;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 787px) {
    .chat__fixed-panel {
      left: 0;
    }
  }

  .chat__input-field {
    min-width: 0;
    min-height: 0;
    max-height: 200px;
    max-width: 570px;
    width: 100%;
  }
</style>
